.loading {
  height: 0;
  width: 0;
  padding: 8px;
  border: 3px solid white;
  border-right-color: #888;
  border-radius: 22px;
  animation: rotate 1s infinite linear;
  margin: 8px;
}

@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
