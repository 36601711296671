@font-face {
  font-family: "SharpGrotesk-Medium25";
  src: local("SharpGrotesk-Medium25"),
    url("./assets/fonts/SharpGrotesk-Medium25.otf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "SharpGrotesk-SemiBold25";
  src: local("SharpGrotesk-SemiBold25"),
    url("./assets/fonts/SharpGrotesk-SemiBold25.otf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "SuisseIntl-Book";
  src: local("SuisseIntl-Book"),
    url("./assets/fonts/SuisseIntl-Book.otf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "SuisseIntl-RegularItalic";
  src: local("SuisseIntl-RegularItalic"),
    url("./assets/fonts/SuisseIntl-RegularItalic.otf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "SuisseIntl-SemiBold";
  src: local("SuisseIntl-SemiBold"),
    url("./assets/fonts/SuisseIntl-SemiBold.otf") format("truetype");
  font-weight: 600;
}

body {
  margin: 0;
  font-family: "SuisseIntl-Book";
}

@media screen and (min-width: 10px) {
    body {
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


#banner {
 position: absolute;
  background-color: #8A63D2;
  display: flex;
  justify-content: center;
  align-items: center;
}