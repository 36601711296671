.tab-navigation {
  background: #0a0a0ad9;
  backdrop-filter: blur(22px);
  position: fixed;
  width: 100%;
  right: 0;
  bottom: 0;
  z-index: 10;

  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  z-index: 100000; //todo: refactor

  .list {
    height: 17px;
    margin-left: 25px;
  }

  .thumb {
    position: relative;
    margin-left: 10px;
    .logo {
      width: 33px;
    height: 33px;
      margin-top: 1px;
    }
    .avatar {
  margin-top: -.5px;
      position: absolute;
      width: 24px;
      height: 24px;
      object-fit: cover;
      top: 6px;
      right: 5px;
      border-radius: 50%;
  box-shadow: 0px 4px 3px 0px rgb(0 0 0 / 14%);
      
      svg {

        width: 24px;
        height: 24px;
      }
    }
  }

  .sign-img {
    height: 31px;
    margin-right: 25px;
    margin-bottom: 2px;
  }

}
.create-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #151515;
  border-radius: 20px 20px 0 0;
  position: relative;

  .title {
    font-family: "SharpGrotesk-SemiBold25";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 33px;
    text-align: center;
    color: #ffffff;
    margin-top: 23px;
    margin-bottom: 0;
    align-items: center;
    display: flex;
    justify-content: center;
  }

  .subtitle {
    width: calc(100% - 50px);
    max-width: 425px;
    margin: 13px auto 28px;
    font-size: 15px;
    text-align: center;
    color: #868686;
    line-height: 20px;

    .link {
      color: #fff;
      text-decoration: none;
      padding: 0 3px;
    }
  }


  .create-image-block {
    width: 113px;
    height: 113px;
    margin: 0 auto;
    position: absolute;
    top: -57px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #151515;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: -2;
  }

  .create-hand-image {
    height: 54px;
    width: 53px;
    margin-top: -35px;
  }

  .create-button {
    width: calc(100% - 40px);
    max-width: 440px;
    border: 0;
    background: #313131;
    height: 44px;
    color: white;
    font-family: "SuisseIntl-SemiBold";
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 44px;
    border-radius: 80px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #ffff;
    margin-bottom: 2px;

    :focus-visible {
      outline: none;
    }
  }
}