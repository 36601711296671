.w3a-header__logo {
  img {
    height: 60px !important;
    width: 60px !important;
  }
}

.w3a-header {
  align-self: center;
  justify-content: center;

  .w3a-header__logo {
    display: flex;
    justify-content: center;
    align-self: center;
  }

  .w3a-header__title {
    text-align: center;
    font-family: "SharpGrotesk-SemiBold25";
  }
  .w3a-header__subtitle {
    text-align: center;
    font-family: "SuisseIntl-Book";
  }
}

.w3a-adapter-item {
  font-family: "SuisseIntl-Book";
}

.w3a-social__policy {
  font-family: "SuisseIntl-Book";
  text-align: center;
  display: flex;
  justify-content: center;
}

.w3ajs-external-wallet {
  text-align: center;

  .w3a-group__title {
    font-family: "SharpGrotesk-Medium25";
    font-size: 14px !important;
  }

  .w3a-button--primary {
    font-family: "SharpGrotesk-SemiBold25";
    color: black !important;
    background: linear-gradient(
      265.42deg,
      #7272ed -9.93%,
      #9572ed -1.63%,
      #d189ef 8.05%,
      #d0cdde 21.19%,
      #aeeccf 32.94%,
      #a0f8e3 47.46%,
      #82c4f5 59.91%,
      #a3f0ea 73.74%,
      #9ad2f4 86.18%,
      #94a1ee 98.63%,
      #d6b7e1 111.07%,
      #b9f8f4 122.83%
    ) !important;
  }
}

.w3a-modal__inner {
  min-height: 550px !important;
}

button {
  img[alt="login-twitter-light"] {
    display: none !important;
  }
  img[alt="login-twitter-active"] {
    display: block !important;
  }
  img[alt="login-facebook-light"] {
    display: none !important;
  }
  img[alt="login-facebook-active"] {
    display: block !important;
  }
  img[alt="login-google-light"] {
    display: none !important;
  }
  img[alt="login-google-active"] {
    display: block !important;
  }
  img[alt="login-apple-light"] {
    display: block !important;
  }
  img[alt="login-apple-active"] {
    display: none !important;
  }
}

.w3a-social__policy {
  text-align: center !important;
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 15px;
}

.walletconnect-modal__footer {
  display: flex;
  flex-wrap: wrap;
}

.crowd-contracts-block {
  margin-top: 33px;
  margin-bottom: 25px;
  display: flex;
  justify-content: center;

  p {
    font-family: "SharpGrotesk-SemiBold25";
    font-size: 20px;
    margin: 0;
    line-height: 20px;
  }
}

.launchcaster-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: #8A63D2; 
  height: 46px;
  text-align: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);


  .launchcaster-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 22px;
    margin-right:3px;
  }
  
  .banner-msg {
    font-family: "SuisseIntl-Book";
    font-size: 15px;
    color: #ffffff;
    text-align: center;
    line-height: 42px; /* This sets the line height to be equal to the height of the banner */
  }
}
