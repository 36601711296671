.side-menu-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100% - 60px);

  a {
    color: white;
    margin: 10px;
    text-decoration: none;
    font-family: "SuisseIntl-Book";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    color: #ffffff;
  }

  .footer {
    position: absolute;
    bottom: 88px;
    display: flex;
    margin-left: 33px;
    flex-direction: column;
    align-items: center;

    p {
      font-family: "SharpGrotesk-SemiBold25";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      text-align: center;
      color: #ffffff;
      margin-bottom: 15px;
      align-items: center;
      display: flex;
      justify-content: center;
      padding: 10px;
    }

    a {
      font-family: "SuisseIntl-book";
      font-size: 13px;
      line-height: 17px;
      margin: 3px;
      opacity: 0.8;
      color: #a9a9a9;
    }
  }
}
