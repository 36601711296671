.social-sign {
  padding: 10px;
  background: #151515;
  backdrop-filter: blur(12px);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-radius: 22px 22px 0px 0px;
  position: relative;
  max-width: 425px;
  margin: 0 auto;

  p {
    margin: 0;
  }

  .close-icon {
    position: absolute;
    top: 30px;
    right: 28px;
  }

  .logo-icon {
    position: absolute;
    background: #151515;
    padding: 13px;
    border-radius: 50%;
    top: -50px;
    align-self: center;
    display: flex;

    .profile-picture {
      height: 56px;
      width: 56px;
      border-radius: 50%;
      position: absolute;
      right: 22px;
      bottom: 21px;
      object-fit: cover;
      box-shadow: 0px 4px 3px 0px rgb(0 0 0 / 14%);
    }
  }

  .title {
    font-family: "SharpGrotesk-SemiBold25";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 33px;
    text-align: center;
    color: #ffffff;
    margin-bottom: 28px;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 50px; // todo: refactor - not the best way

    img {
      height: 18px;
      width: 18px;
      margin-right: 4px;
      margin-bottom: 4px;
    }

    &.error {
      margin-bottom: 12px;
    }
  }

  .signed-in-description {
    text-align: center;
    font-family: "SuisseIntl-Book";
    font-size: 15px;
    font-weight: 450;
    font-style: normal;
    color: #868686;
    margin: 0;
    margin-bottom: 18px;
    padding: 0 35px;
  }

  .public-address {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 0;
    background: #ffffff;
    border-radius: 100px;
    position: relative;
    height: 44px;
    margin-bottom: 10px;

    &:disabled {
      opacity: 0.5;
    }

    .token-img {
      margin-right: 5px;
      height: 15px;
    }

    p {
      font-family: "SuisseIntl-Book";
      font-size: 15px;

      color: #000000;
    }

    .status {
      position: absolute;
      right: 18px;
      width: 7px;
      height: 7px;
      border-radius: 88px;
      background: #35d932;
    }
  }

  .or-text {
    font-family: "SuisseIntl-Book";
    font-style: normal;
    font-weight: 450;
    font-size: 15px;
    color: #868686;
    text-align: center;
    margin-bottom: 15px;
  }

  .skip-btn {
    background: #2f2f2f;
    border-radius: 100px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    border: 0;
    height: 44px;

    font-family: "SuisseIntl-Book";
    font-size: 15px;
    color: #ffffff;
  }

  .terms-policy-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-family: "SuisseIntl-Book";
    font-size: 13px;
    text-align: center;
    color: #676767;
    margin-bottom: 25px;

    b {
      color: #a9a9a9;
    }
  }

  .disconnect-btn {
    display: flex;
    border: 0;
    background: transparent;
    color: white;
    justify-content: center;
    align-items: center;
    font-family: "SuisseIntl-Book";
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    line-height: 14px;
    color: #ffffff;
    margin: 35px 0;

    img {
      margin-right: 5px;
    }
  }
}
