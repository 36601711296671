.hero {
  display: flex;
  flex-direction: column;
  padding: 0 12px;
  margin-top: 6px;
  justify-content: center;
  align-items: center;

  // Added appropriate top block width small logo & share icon above the main logo
  .hero-top-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;

    
    
    //Was 30px height before test removal
    .connect-button-image {
      width: auto;
      height:0px;
    }
    .share-button {
      background: transparent;
      border: none;
      cursor: pointer;
      

    //Was 38px height before test removal
      svg {
        height: 0px;
        cursor: pointer;
      }

      &:disabled {
        opacity: 0.5;
      }
    }
  }

  .hero-bottom-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      margin-top: 5px;
      height: 72px;
      width: auto;
    }

    .hero-logo-label {
      font-family: "SharpGrotesk-SemiBold25";
      font-size: 20px;
      color: #777a7a;
      margin-top: 12px;
      margin-bottom: 5px;
      line-height: 33px;
    }
  }
}

.share-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #151515;
  border-radius: 20px 20px 0 0;

  .title {
    font-family: "SharpGrotesk-SemiBold25";
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 33px;
    text-align: center;
    color: #ffffff;
    margin-top: 30px;
    align-items: center;
    display: flex;
    justify-content: center;
    margin-bottom: 0;
  }
  
  .copy-link {
    margin-bottom: 15px;
    margin-top: 10px; 
    color: #fff;
    text-decoration: underline;
  } 

  .button-group {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    button {
      margin: 5px 8px;
      border-radius: 10px;

      svg {
        border-radius: 10px;
        
      }
    }
  }
}
