.social-sign-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: 0;

  border-radius: 100px;
  position: relative;
  height: 44px;
  margin-bottom: 15px;

  &:disabled {
    opacity: 0.5;
  }

  &.Farcaster {
    background: #8367cb;
    margin-bottom: 15px;
  }

  &.Twitter {
    background: #1d9bf0;
    margin-bottom: 10px;
  }

  .type-img {
    margin-right: 6px;
    margin-bottom: 1px;
    height: 15px;
  }

  p {
    font-family: "SuisseIntl-Book";
    font-size: 15px;

    color: #ffffff;
  }

  .right-img {
    position: absolute;
    right: 18px;
  }
}
