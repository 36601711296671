.conditions {
  display: flex;
  flex-direction: column;
  position: relative;
  .line {
    width: 2px;
    height: calc(100% - 125px);
    background: #ebebeb;
    position: absolute;
    top: 25px;
    left: 25px;
    z-index: 1;

    img {
      position: absolute;
      bottom: -5px;
      left: -5px;
    }

    &.complete {
      left: 45px;
      height: calc(100% - 90px);
    }

    &.delivered {
      left: 45px;
      height: calc(100% - 80px);
    }

    &.failure {
      left: 35px;
      height: calc(100% - 70px);
    }

    &.expanded {
      height: calc(100% - 117px);
    }
  }

  .static-loading {
    height: 4px;
    align-self: flex-end;
    position: absolute;
    right: 0;
    bottom: -13px;
    fill: #d4d4d4;

    .validated {
      margin: 0;
      font-family: "SuisseIntl-Book";
      font-size: 12px;
      line-height: 19px;
      color: #979797;
      margin-top: -5px;
    }
  }
  .conditions-block {
    position: relative;
    padding: 13px 19px 13px 15px;
    background: #ffffff;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.14);
    border-radius: 8px;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    margin-bottom: 25px;
    z-index: 2; // todo: remove and add proper line connectors
    width: fit-content;
    
    p {
      margin: 0;
      font-family: "SuisseIntl-SemiBold";
      font-size: 15px;
      line-height: 19px;
      color: #212121;
    display: inline-block;
      // Removes the width so that later we can calculate it to remove unneeded paddings
      display: inline;
    }

    &.incomplete {
      border-left: 5px solid #dbbc0e;
    }

    &.completing {
      border-left: 5px solid #12c672;
      margin-right: 12px;
      margin-left: 45px;
      margin-bottom: 0;

      .static-loading {
        bottom: -7px;

        .validated {
          color: #949494;
        }
      }
    }

    &.complete {
      border-left: 5px solid #12c672;
      margin-bottom: 35px;
    }

    &.failure {
      border-left: 5px solid #dadada;
      margin-bottom: 25px;
      p {
        opacity: 0.4;
      }
    }

    &.delivered {
      border-left: 5px solid #e5c9ef;
      background: linear-gradient(
        268.96deg,
        #f5eaff 3.81%,
        #eff3ff 51.3%,
        #eafbff 95.91%
      );
      margin-bottom: 35px;
    }
  }

  .state-tag {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.14);
    border-radius: 8px;
    margin-top: 10px;
    margin-bottom: 20px;
    z-index: 2; // todo: remove and add proper line connectors
    width: fit-content;

    &.pending {
      width: 50px;
      height: 27px;
    }

    .text {
      padding: 5px 10px;
      font-family: "SuisseIntl-SemiBold";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 14px;
      width: fit-content;
    }
  }

  .show-hide-toggle {
    background: #ffffff;
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 6.5px 10px;
    width: fit-content;
    margin-bottom: 20px;
    margin-left: 47px;
    z-index: 2; // todo: remove and add proper line connectors
    align-self: flex-start;
    cursor: pointer;

    p {
      margin: 0;
      font-family: "SuisseIntl-SemiBold";
      font-size: 15px;
      line-height: 14px;
      color: #777a7a;
    }

    &.expanded > p {
      color: black;
    }
  }

  .if-verified-text {
    margin-top: 16px;
    p {
      margin: 0;
      margin-top: 15px;

      margin: 0;
      font-family: "SuisseIntl-SemiBold";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 14px;

      color: #777a7a;
    }

    &.expanded {
      margin-top: 50px;
    }

    &.complete {
      margin-top: 20px;
      margin-left: 10px;
    }
  }

  .results-block {
    width: fit-content;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;
    margin-top: 19px;

    p {
      margin: 0;
      font-family: "SuisseIntl-SemiBold";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      color: #777a7a;
      line-height: 19px;
      margin-left: 20px;
    }
  }

  .final-remarks-block {
    margin-top: 20px;
    z-index: 2;

    p {
      margin: 0;
      font-family: "SuisseIntl-SemiBold";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 14px;
      color: black;
      margin-left: 12px;
      margin-bottom: 15px;
      background-color: white;
      padding: 5px;
    }
  }

  .result-profile-stack {
    height: 75px;
    width: 75px;
    align-self: center;
    margin-bottom: 25px;

    .connector {
      width: calc(50% - 110px);
      height: 2px;
      background: #ebebeb;
      align-self: flex-end;
      position: absolute;
      bottom: 50px;
      left: 60px;
      z-index: 1;

      .arc {
        border: 2px solid #ebebeb;
        display: inline-block;
        min-width: 10px;
        min-height: 10px;
        padding: 0.5em;
        border-radius: 50%;
        border-left-color: transparent;
        border-right-color: transparent;
        border-top-color: transparent;
        position: absolute;
        top: -28px;
        left: -15px;
        transform: rotate(45deg);
      }
    }

    .stack {
      position: absolute;
      transform: scale(0.75);
      bottom: 125px;
      left: calc(50% - 52px);

      .image-avatar:nth-child(2) {
        margin-left: -15px;
        transform: rotate(-20deg);
      }

      .image-avatar:nth-child(1) {
        margin-left: 20px;
        transform: translateY(10px) rotate(20deg);
      }
    }
  }
}

.dot-flashing {
  position: relative;
  width: 7px;
  height: 7px;
  border-radius: 7px;
  background-color: #dbbc0e;
  color: #dbbc0e;
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.5s;
}
.dot-flashing::before,
.dot-flashing::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
}
.dot-flashing::before {
  left: -10px;
  width: 7px;
  height: 7px;
  border-radius: 7px;
  background-color: #dbbc0e;
  color: #dbbc0e;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 0s;
}
.dot-flashing::after {
  left: 10px;
  width: 7px;
  height: 7px;
  border-radius: 7px;
  background-color: #dbbc0e;
  color: #dbbc0e;
  animation: dot-flashing 1s infinite alternate;
  animation-delay: 1s;
}

@keyframes dot-flashing {
  0% {
    background-color: #dbbc0e;
  }
  50%,
  100% {
    background-color: #dbbc0e;
    opacity: 0.5;
  }
}
