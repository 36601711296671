.drawer-wrapper {
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
}


.crowd-contracts-block {
  margin-top: 48px;
  margin-bottom: 26px;
  display: flex;
  flex-direction: column;
  align-items: center;

  p {
    font-family: "SharpGrotesk-SemiBold25";
    font-size: 20px;
    margin-top: 5px;
    line-height: 20px;
    text-color: #212121;
  }

  .crowd-icon {
    width: 11px;
    margin-top: 7px;
  }
}