.signers {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .avatar-stack {
    margin-right: -2px;
    display: inline-flex;
    flex-direction: row-reverse;
  }

  .avatar {
    position: relative;
    border: 2px solid #fff;
    border-radius: 50%;
    overflow: hidden;
    width: 27px;
    height: 27px;
  }

  .avatar:not(:last-child) {
    margin-left: -14px;
  }

  
  .avatar-image {
    height: 27px;
    width: 27px;
    display: block;
    outline: 1px solid rgba(#c0c0c0, .0);
    outline-offset: -1px;
    border-radius: 50%;
  }

  .count {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 2px;

    .count-text {
      margin: 0;
      margin-top: 1px;
      color: #212121;
      font-family: "SharpGrotesk-Medium25";
      font-size: 17px;
      margin-bottom: 2px;
    }

    img {
      height: 18px;
      margin-right: 2.5px;
      margin-bottom: 4px;
    }
  }
}
