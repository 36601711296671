.image-avatar {
  height: 104px;
  width: 88px;
  object-fit: cover;
  border-radius: 8px;
  position: absolute;
  top: 58px;
  left: 24px;

  // Made a backup plan for the profile image to have it as on the deisgn but wirhout cut angle
  .profile-wrapper-shadow {
    filter: drop-shadow(0px 2.98846px 13.9461px rgba(0, 0, 0, 0.15));
  }

  .profile-container {
    width: 88px;
    height: 104px;
    position: absolute;
    background: rgba(256,256,256, 0.12);
    border-radius: 14px;
  }

  .profile-wrapper {
    position: absolute;
    overflow: hidden;
    padding: 8px;
    height: 88px;
    width: 72px;
    box-shadow: 0px 2.98846px 13.9461px rgba(0, 0, 0, 0.14);
    border-radius: 15px;
  }

  .profile-outline {
    position: relative;
    width: 100%;
    height: 100%;
  }

  .profile-outline::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    border: 1px solid rgba(256,256,256,0.2);;
    border-radius: 8px;
    height: 86px;
    width: 70px;
  }

  .profile {
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 8px;
  }

  .frame {
    position: absolute;
    left: -29px;
    top: -25px;
  }

  .claim-gif {
    position: absolute;
    left: 0px;
    top: 0px;
    height: 104px;
    border-radius: 14px;
    width: 88px;
  }
}
