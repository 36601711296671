.sign {
  background: white;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
  display: flex;
  flex-direction: column;
  z-index: 10;
  bottom: 0;
  overflow: auto;
  height: 100%;
  align-items: center;

  &::-webkit-scrollbar {
    display: none;
  }

  p {
    margin: 0;
    
  }

  .non-stake {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url("./../../../assets/Confetti.gif");
    background-size: contain;

    .header-content {
      height: 233px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      .modal-close {
        position: absolute;
        top: 36px;
        right: 30px;
        width: 14px;       
        margin-bottom: 0px;
      }

      .heading {
        font-family: "SuisseIntl-Book";
        font-style: normal;
        font-weight: 450;
        font-size: 15px;
        line-height: 22px;
        color: #535353;
        margin-top: 33px;
        margin-bottom: 10px;
      }

      .title {
        font-family: "SharpGrotesk-SemiBold25";
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        max-width: 425px;
        line-height: 25px;
        color: #000000;

        margin-bottom: 24px;
        padding: 0 20px;
        text-align: center;
      }

      .avatar {
        position: relative;
        height: 93px;
        width: 93px;
        justify-content: center;
        display: flex;
        align-items: center;
        margin-bottom: 17px;

        .logo {
          position: absolute;
          height: 92px;
          width: 92px;
        
        }

        .profile {
          position: absolute;
          height: 71px;
          width: 71px;
          border-radius: 50%;
          object-fit: cover;
          box-shadow: 0px 4px 3px 0px rgb(0 0 0 / 14%)
        }

        svg {
          z-index: 2;
          border-radius: 50%;
        }
      }
    }

    .share-icon {
      width: 41px;
      height: 35px;
      margin-bottom: 14px;
      margin-top: 14px;
      fill: #949494;
      opacity: 0.6;
      cursor: pointer;
      filter: drop-shadow(3px 5px 2px rgb(0 0 0 / 0.4));
    }

    .description {
      padding: 0 32px;
      font-family: "SuisseIntl-Book";
      font-style: normal;
      font-weight: 450;
      font-size: 15px;
      line-height: 21px;
      text-align: center;
      margin-bottom: 50px;

      color: #949494;
      b {
        margin: 0 2px;
        font-family: "SuisseIntl-SemiBold";
      }
    }

    .signers-count {
      font-family: "SharpGrotesk-Medium25";
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 25px;
      letter-spacing: -0.02em;
      margin-bottom: 11px;
      margin-top: 26px;
      display: flex;
      align-items: center;
      -webkit-align-items: center;
      width: 100%;
      justify-content: center;
      text-align-last: end;

      color: #000000;

      img {
        height: 18px;
        margin-bottom: 2px;
        margin-right: 4px;
      }
    }
  }

  .stake-window {
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.35);
    background: black;
    position: absolute;
    bottom: 60px;
    height: 183px;
    width: 100%;
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    display: flex;
    flex-direction: column;
    transition: height .3s;

    &.expanded {
      height: 420px;
    }

    .toggle {
      width: 55px;
      height: 3px;
      align-self: center;
      background: #404040;
      border-radius: 9px;
      
      margin-top: 15px;
    }

    .title {
      display: flex;
      flex-direction: row;
      margin-top: 25px;
      margin-bottom: 28px;
      justify-content: center;

      img {
        height: 18px;
        margin-top: 3px;
        margin-right: 4px;
      }

      p {
        font-family: "SharpGrotesk-SemiBold25";
        font-style: normal;
        font-weight: 600;
        font-size: 17px;
        margin-bottom: -10px;
        line-height: 25px;
        color: #ffffff;
      }
    }

    .input-wrapper {
      display: flex;
      justify-content: space-between;
      width: calc(120% - 40px);
      padding: 0 20px;
      position: relative;

      max-width: 425px;
      align-self: center;

      .currency-icon {
        position: absolute;
        width: 12px;
        left: 35px;
    margin-top: 4px;
        height: 20px;
      }

        
        input {
        width: 100%;
        height: 45px;
        background: #323232;
        border: 0;
        border-radius: 12px;
        padding-left: 35px;
        font-family: "SharpGrotesk-Medium25";
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 17px;
        margin-top: -9px;
        outline: none;
        color: #8c8c8c;

        &.internal {
          padding-left: 20px;
        }
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      .currency-dropdown {
        position: absolute;
        background: black;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px;
        border-radius: 14px;
        right: 28px;
        bottom: 6px;
        img {
          height: 5px;
          margin-left: 3px;
        }

        p {
          font-family: "SuisseIntl-Book";
          font-style: normal;
          font-weight: 500;
          font-size: 12.39px;
          line-height: 14px;
          color: #ffffff;
        }

        .list-wrapper {
          position: absolute;
          bottom: -30px;
          z-index: 1;
          background: black;
          color: white;

          font-family: "SuisseIntl-Book";
          font-style: normal;
          font-weight: 500;
          font-size: 12.39px;
          line-height: 14px;
          color: #ffffff;

          padding: 5px;
          border-radius: 8px;
          border: 1px solid rgb(102, 100, 100);

          div {
            padding: 2px;
          }
        }
      }
    }
    .balance-details {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding: 0 20px;
      margin-top: 7px;
      

      max-width: 425px;
      align-self: center;
      justify-content: space-between;
      width: 100%;

      &.external {
        justify-content: space-between;
      }

      p {
        color: white;
      }

      .available-balance {
        font-family: "SuisseIntl-SemiBold";
        font-style: normal;
        margin-left: 3px;
        margin-bottom: 10px;
        font-weight: 600;
        font-size: 11px;
        line-height: 15px;
        letter-spacing: 0.02em;
        color: rgb(102, 100, 100);
      }

      .public-address {
        max-width: 30%;
        margin-right: 3px;
        margin-bottom: 10px;
        margin-top: -2px;
        display: flex;
        flex-direction: row;
        align-items: center;
        opacity: 0.9;
        p {
          font-family: "SuisseIntl-semibold";
          font-style: normal;
          font-weight: 450;
          font-size: 11px;
          line-height: 13px;
          letter-spacing: 0.02em;
          color: #696969;
          overflow: hidden;
          text-overflow: ellipsis;

        }

        .public-address-img {
          height: 11px;
          margin-right: 1px;
          color: green;
        }
      }
    }

    .stake-btn {
      margin: 12px 20px 0;
      font-family: "SharpGrotesk-SemiBold25";
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
    
      line-height: 25px;
      position: relative;

      max-width: 500px;
      display: flex;
      align-self: center;
      width: 100%;
      justify-content: center;

      color: #ffffff;
      background: #464646;
      border: 0;
      padding: 12px;
      border-radius: 25px;

      &:disabled {
        color: #4b4b4b;
        background: #232323;
        .arrow-icon {
          opacity: 0;
        }
      }

      .arrow-icon {
        position: absolute;
        right: 20px;
        top: 17px;

        transition: all 0.3s;
      }
    }

    .learn-more-text {
      font-family: "SuisseIntl-Book";
      
      font-style: normal;
      font-weight: 450;
      font-size: 13px;
      line-height: 18px;
      color: #808080;
      padding: 20px 35px;
      text-align: center;
      max-width: 350px;
      margin: 0 auto;

      b {
        color: #b2b2b2;
      }
    }

    .cancel-btn {
      font-family: "SuisseIntl-SemiBold";
      font-style: normal;
      font-weight: 600;
      font-size: 15px;
      line-height: 39px;
      letter-spacing: -0.02em;
      background: black;
      border: 0;
      color: #ffffff;
    }

    &:where(.error, .enabled, .success) {
      .input-wrapper {
        input {
          color: #ffffff;
        }
      }
    }

    &.error {
      .input-wrapper {
        input {
        box-shadow:inset 0px 0px 0px 1px #EE4310
;
        }
      }

      .balance-details {
        .available-balance {
          color: #ee4310;
        }
        .public-address > p {
          color: #ee4310;
        }
      }
    }

    &.success {
      .balance-details {
        .available-balance {
          color: #12c672;
        }
        .public-address > p {
          color: #12C672;
        }
      }
    }
  }

  &.mobile {
    .stake-window {
      z-index: 4;
      .input-wrapper {
        max-width: 350px;
      }

      .balance-details {
        max-width: 350px;
      }

      .stake-btn {
        max-width: 350px;
        height: 45px;
      }
    }
  }
}
