.signers-list {
  width: 100%;
  background: #f2f2f2;
  border-radius: 14px;

  .header {
    display: grid;
    grid-template-columns: 13% 51% auto;
    justify-items: stretch;
    align-items: center;
    padding: 23px 25px 12px 25px;

    p {
      font-family: "SuisseIntl-Book";
      font-style: normal;
      font-weight: 600;
      font-size: 11px;
      line-height: 100%;
      color: #777A7A;
      margin-bottom: -2px;
      text-transform: uppercase;
    }

    select {
      text-align: end;
    }

    p:nth-child(1) {
      min-width: 13%;
    }

    p:nth-child(2) {
      min-width: 51%;
      margin-left: 56px;
    }


    .filter-dropdown {
      opacity: 1;
      font-family: "SuisseIntl-SemiBold";
      font-style: normal;
      font-weight: 600;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      text-transform: uppercase;
      border: 0;
      background: inherit;
      font-size: 11px;
      color: #212121;
      margin-bottom: -2px;
      // margin-right: -3px;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
    }
  }

  .signer-cell {
    display: grid;
    grid-template-columns: 13% 51% auto;
    grid-column-gap: 0px;
    grid-row-gap: 7px;
    justify-items: stretch;
    align-items: center;
    
    padding: 14px 25px;
    background: white;
    margin: 3px 5px;
    border-radius: 8px;

    &.current {
      margin: 0 0 7px 0;
      border-radius: 0;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
      
      .profile { margin-left: 0; }
      .amount { margin-right: 0; }
    }

    .sn {
      min-width: 6%;
      display: flex;
      align-items: center;

      p {
        font-family: "SuisseIntl-Book";
        font-style: normal;
        font-weight: 600;
        font-size: 13px;
        margin-top: 2px;
        line-height: 37px;
        text-align: left;
        color: #212121;

        &.margin-left-3 { margin-left: -3px; }
        &.margin-left-5 { margin-left: -5px; }
      }
    }

    .profile {
      min-width: 67%;
      margin-left: -3px;

      display: flex;
      flex-direction: row;
      align-items: center;
      overflow: hidden;

      .avatar-signer {
        width: 44px;
        height: 44px;
        margin-right: 12px;
        border-radius: 50%;
        object-fit: cover;
          
      }

      .name-grp {
        display: flex;
        flex-direction: column;

        .name {
          display: flex;
          flex-direction: row;
          align-items: center;
          font-size: 15px;
          p {
            font-family: "SuisseIntl-SemiBold";
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 20px;
            color: #000000;
          }

          img {
            height: 14px;
            width: 14px;
            margin-top: 2px;
            margin-left: 4px;
          }
        }

        .sub-name {
          font-family: "SuisseIntl-Book";
          font-style: normal;
          font-weight: 450;
          font-size: 14px; // todo: design has 15px, doesn't feel right to me
          line-height: 17px;
          color: #949494;
          margin-top: 1px;
        }
      }
    }

    .amount {
      min-width: 27%;
      margin-right: -3px;

      display: flex;
      flex-direction: column;
      align-items: flex-end;
      align-self: center;

      .usd {
        font-family: "SharpGrotesk-Medium25";
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.02em;
        margin-top: 3px;
        color: #212121;
      }

      .token-value {
        display: flex;
        flex-direction: row;
        align-items: center;

        .token-img {
         height: 13px;
          margin-right: 3px;
          margin-top: -2px;
        }

        p {
          font-family: "SuisseIntl-Book";
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 19px;
          text-align: right;
          margin-top: -2px;
          color: #DBBC0E;
        }
      }

      .nilli-earned {
        padding: 8px 8px 6px 8px;
        border-radius: 100px;
        background: #f8f2cf;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          margin-right: 3px;
          margin-bottom: 2px;
        }

        p {
          font-family: "SharpGrotesk-Medium25";
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          letter-spacing: 0.02em;
          color: #000000;
          align-self: center;
        }
      }

      .date-signed {
        display: flex;
        align-items: center;
        img {
          height: 14px;
          margin-right: 4px;
          margin-top: 1px;
          margin-bottom: 1px;
        }

        p {
          font-family: "SuisseIntl-Book";
          font-style: normal;
          font-size: 15px;
          line-height: 30px;
          color: #777a7a;
        }

        p > b {
          font-family: "SuisseIntl-SemiBold";
          font-style: normal;
          font-weight: 600;
        }
      }
    }
  }
}
