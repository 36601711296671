:root {
  --switches-bg-color: #f2f2f2;
  --switches-label-color: #525252;
  --switch-bg-color: white;
  --switch-text-color: black;
}

.filter {
  padding: 0 20px;
  margin-top: 35px;
  margin-bottom: 40px; // todo remove

  p {
    margin-top: 2rem;
    font-size: 0.75rem;
    text-align: center;
  }

  .switches-container {
    position: relative;
    display: flex;
    padding: 0;
    position: relative;
    background: var(--switches-bg-color);
    line-height: 3rem;
    border-radius: 3rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 322px;

    .text {
      font-family: 'SharpGrotesk-SemiBold25';
      font-size: 16px;
      line-height: 37px;
      letter-spacing: -0.02em;

      color: #212121;
    }
  }

  .switches-container input {
    visibility: hidden;
    position: absolute;
    top: 0;
  }

  .switches-container label {
    width: 50%;
    padding: 0;
    margin: 0;
    cursor: pointer;
    color: #777A7A;
    opacity: .85;
    display: flex;
    margin-top: 1px;
    align-items: center;
    justify-content: center;
    font-family: "SharpGrotesk-Medium25";
  }

  .switch-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 50%;
    border: 2px solid black;
    border-radius: 3rem;

    z-index: 3;
    transition: transform 0.3s cubic-bezier(0.77, 0, 0.175, 1);
    box-shadow: 0px 4px 11px rgba(0, 0, 0, 0.23);
  }

  .switch {
    border-radius: 3rem;
    background: var(--switch-bg-color);
    height: 100%;
  }

  .switch div {
    width: 100%;
    text-align: center;
    opacity: 0;
    display: block;
    color: var(--switch-text-color);
    transition: opacity 0.2s cubic-bezier(0.77, 0, 0.175, 1) 0.125s;
    will-change: opacity;
    position: absolute;
    top: -1px;
    left: -2px;
    font-family: "SharpGrotesk-SemiBold25";

    display: flex;
    justify-content: center;
    align-items: center;

    img {
      margin-right: 2px;
      width: 21px;
      height: 20.5px;
      margin-bottom: 5px;
    }

  }

  .switches-container input:nth-of-type(1):checked ~ .switch-wrapper {
    transform: translateX(0%);
  }

  .switches-container input:nth-of-type(2):checked ~ .switch-wrapper {
    transform: translateX(96%);
  }

  .switches-container
    input:nth-of-type(1):checked
    ~ .switch-wrapper
    .switch
    div:nth-of-type(1) {
    opacity: 1;
  }

  .switches-container
    input:nth-of-type(2):checked
    ~ .switch-wrapper
    .switch
    div:nth-of-type(2) {
    opacity: 1;
  }
}
