.card {
  width: 100%;
  background: white;
  border-radius: 22px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
  position: relative;
  margin-bottom: 24px;

  .image-overlay {
    height: 132px;
    width: 100%;
    object-fit: cover;
    background-position: bottom;
    background-attachment: fixed;
    border-top-right-radius: 21px;
    border-top-left-radius: 21px;
    // Added inline-block to remove wierd space under the iamge block
    display: inline-block;
  }

  .logo {
    position: absolute;
    right: 23px;
    top: 83px;
    height: 79px;
  }

  .user-avatar {
    position: absolute;
    right: 31px;
    top: 91px;
    height: 63px;
    width: 63px;
    object-fit: cover;
    cursor: pointer;
    border-radius: 50%;
    //outline: 1px solid rgba(#c0c0c0, 0.22)//; 
    outline-offset: -1px;
    box-shadow: 0px 4px 3px 0px rgb(0 0 0 / 14%);
  }

  .share-icon {
    position: absolute;
    right: 26px;
    top: 24px;
    height: 30px;
    fill: white;
    cursor: pointer;
    filter: drop-shadow(0px 5px 5px rgb(0 0 0 / 0.14));
  }

  .content {
    margin-top: 46px;
    width: calc(100% - 48px);
    padding: 0 24px 20px 24px;
    display: flex;
    flex-direction: column;

    .heading {
      font-family: "SharpGrotesk-SemiBold25";
      font-size: 17px;
      margin: 0;
      margin-top: -1px;
      line-height: 23px;
      text-color: #212121;
    }

    .progress-target {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 11px;

      progress {
        border: none;
        width: 100%;
        margin-right: 10px;
      }

      progress {
        height: 7px;
        border-radius: 100px;
          border-right-radius: 50px;
      }

      progress::-moz-progress-bar {
        border-radius: 100px;
        border-top-right-radius: 100px;
        border-bottom-right-radius: 100px;
      }

      progress::-webkit-progress-value {
        border-radius: 100px;
        border-top-right-radius: 100px;
        border-bottom-right-radius: 100px;
      }

      progress::-webkit-progress-bar {
        background: #ededed;
        border-radius: 100px;
      }

      progress.complete {
        &::-moz-progress-bar {
          background: #12c672;
        }

        &::-webkit-progress-value {
          background: #12c672;
        }
      }

      progress.pending {
        &::-moz-progress-bar {
          background: #dbbc0e;
        }

        &::-webkit-progress-value {
          background: #dbbc0e;
        }
      }

      progress.failure {
        &::-moz-progress-bar {
          background: #f24310;
          border-radius: 100px;
        }

        &::-webkit-progress-value {
          background: #f24310;
          border-radius: 100px;
        }
      }

      progress.delivered {
        &::-moz-progress-bar {
          background: linear-gradient(
            265.42deg,
            #7272ed -9.93%,
            #9572ed -1.63%,
            #d189ef 8.05%,
            #d0cdde 21.19%,
            #aeeccf 32.94%,
            #a0f8e3 47.46%,
            #82c4f5 59.91%,
            #a3f0ea 73.74%,
            #9ad2f4 86.18%,
            #94a1ee 98.63%,
            #d6b7e1 111.07%,
            #b9f8f4 122.83%
          );
          border-radius: 100px;
        }

        &::-webkit-progress-value {
          background: linear-gradient(
            265.42deg,
            #7272ed -9.93%,
            #9572ed -1.63%,
            #d189ef 8.05%,
            #d0cdde 21.19%,
            #aeeccf 32.94%,
            #a0f8e3 47.46%,
            #82c4f5 59.91%,
            #a3f0ea 73.74%,
            #9ad2f4 86.18%,
            #94a1ee 98.63%,
            #d6b7e1 111.07%,
            #b9f8f4 122.83%
          );
          border-radius: 100px;
        }
      }

      .progress-target-text {
        margin: 0;
        font-family: "SuisseIntl-Book";
        font-size: 11px;
        line-height: 12px;
        color: #525252;
      }
    }

    .description {
      display: flex;
      flex-direction: row;
      margin-top: 11px;
      align-items: center;
      .staked-text {
        margin: 0;
        margin-bottom: 1px;
        color: #777a7a;

        .current-amount {
          font-family: "SuisseIntl-SemiBold";
          font-size: 15px;
          line-height: 15px;
          color: black;
          margin-right: 5px;
        }

        .state {
          font-family: "SuisseIntl-Book";
          font-size: 15px;
          font-weight: 400; // todo ask for light font
          margin-right: 5px;
          padding-bottom: 2px;
        }

        .helper {
          font-family: "SuisseIntl-Book";
          font-size: 15px;
          font-weight: 400; // todo ask for light font
          margin-right: 5px;
        }
      }

      .staked-for-card {
        padding: 5px 7px;
        background: #ffffff;
        box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.14);
        border-radius: 8px;
        overflow: hidden;
        display: flex;
        align-items: center;

        &.delivered {
          background: linear-gradient(
            268.96deg,
            #f5eaff 3.81%,
            #eff3ff 51.3%,
            #eafbff 95.91%
          );
        }

        p {
          margin: 0;
          color: #525252;
          font-family: "SuisseIntl-SemiBold";
          overflow-wrap: break-word;
          font-size: 15px;

          &.verified-text {
            color: #525252;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }

        .profile {
          width: 17px;
          height: 17px;
          border-radius: 6px;
          margin-right: 5px;
        }

        .verified-img {
          width: 14px;
          margin-left: 3px;
        }
      }
    }

    .footer {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .footer-text {
        margin: 0;
      }

      .date-user {
        display: flex;
        flex-direction: column;

        .date {
          font-size: 12px;
          color: #949494;
          font-weight: 450; // todo ask light font
          line-height: 19px;
          margin-bottom: 9px;
          margin-top: 9px;

          &.hide {
            opacity: 0;
            margin-top: 0;
          }
        }

        .user {
          display: flex;
          flex-direction: row;

          .avatar {
            height: 27px;
            width: 27px;
            border-radius: 50%;
          }

          .user-name {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: 7px;

            p {
              color: #525252;
              font-size: 15px;
            }

            img {
              height: 13px;
              margin-top: 1px;
              margin-left: 4px;
            }
          }
        }
      }
    }

    .note-message {
      display: flex;
      flex-direction: row;
      margin-top: 5px;
      margin-bottom: 24px;
      align-items: flex-start;

      img {
        height: 13px;
        margin-right: 7px;
        align-self: flex-start;
        margin-top: 4px;
      }

      p {
        margin: 0;
        font-family: "SuisseIntl-RegularItalic";
        font-style: italic;
        font-weight: 400; // todo ask for lighter fonts
        font-size: 15px;
        line-height: 20px;
        margin-top: 1px;
        color: #777a7a;
      }
    }

    .sign-btn {
      width: calc(100% + 16px);
      border: 0;
      background: #151515;
      color: white;
      font-family: "SharpGrotesk-SemiBold25";
      font-style: normal;
      font-weight: 600;
      font-size: 17px;
      line-height: 39px;
      padding: 5px;
      border-radius: 100px;
      margin-top: 18px;
      margin-left: -8px;
      position: relative;
      display: flex;
      justify-content: center;
      cursor: pointer;
      box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, .3);

      &.sigining {
        background: #525252;
      }

      &:disabled {
        background: #424242;
      }

      &.signed {
        background: #4e4e4e;
      }

      .signin-button-image {
        height: 18px;
        position: absolute;
        left: calc(50% - 34px);
        top: 14px;
      }

      .signin-button-text.margin-left {
        margin-left: 20px;
      }
    }

    .sign-btn:active {
      transform: translateY(1px);
      
      box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, .1);
    }
  }
}
